import React, {
  type FC,
  useEffect,
  useId,
  useState
} from 'react';

import { type NextFont } from 'next/dist/compiled/@next/font';
import Link from 'next/link';
import { type DefaultSession } from 'next-auth';

import { type PropTypes, type Props } from '../typescript';
import { ifCondition } from '../utils/functions';
import useInput from '../utils/hooks/useInput';
import {
  type Search,
  usePostSearchMutation
} from '../utils/services/ApiConnection';

import {
  Arrow,
  FeaturesTopLeft,
  FeaturesBottomLeft,
  FeaturesTopRight,
  FeaturesRightGroup,
  FeaturesTopLeftMobile,
  FeaturesBottomLeftMobile,
  FeaturesRightGroupMobile,
  FeaturesTopRightMobile
} from './icons';
import { CategoriesCard, Loading, SearchBar, VoteCard } from './util';
import useDebounce from '@util/hooks/useDebounce';

interface HeroProps {
  font: NextFont
}
const Hero: FC<PropTypes<Props, HeroProps>> = ({
  font
}) => {
  const [search, changeSearch, clearSearch] = useInput('');
  const [getSearch, { data, isLoading }] = usePostSearchMutation();
  const searchQuery = useDebounce(search);
  const [show, setShow] = useState(false);
  useEffect(() => {
    getSearch({search: searchQuery});
    }, [searchQuery])
  const Id = useId();
  useEffect(() => {
    setShow(true);
  }, []);
  const countries = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia",
    "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin",
    "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi",
    "Cabo Verde", "Cambodia", "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia",
    "Comoros", "Congo, Democratic Republic of the", "Congo, Republic of the", "Costa Rica", "Croatia", "Cuba", "Cyprus",
    "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador",
    "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia",
    "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti",
    "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy", "Jamaica",
    "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South", "Kosovo", "Kuwait", "Kyrgyzstan",
    "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar",
    "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia",
    "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal",
    "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway", "Oman", "Pakistan", "Palau",
    "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar", "Romania",
    "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino",
    "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia",
    "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname",
    "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga",
    "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates",
    "United Kingdom", "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Yemen",
    "Zambia", "Zimbabwe"
  ];
  function shuffleArray(array: Array<string>) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  }

  const elements = []
  for (let items in shuffleArray(countries)){
    elements.push(` ${countries[Number(items)]}`)
    elements.push(5000)
  }
  return (
    <div
      className={`flex w-full min-w-screen flex-col items-center overflow-hidden bg-[#1F0231] text-white pt-24 pb-20 ${font.className} min-h-screen items-center`}
    >
      <SearchBar
        className="w-full"
        typing={true}
        heading={elements}
        isHome
        {...{ search, changeSearch, clearSearch }}
      />
      {search !== ''
        ? (
            ifCondition(
              !isLoading,
              ifCondition(
                // !(data == null) && data?.length > 0,
                false,
            <div className="grid grid-cols-1 gap-8 p-6 md:grid-cols-2 md:p-8 lg:grid-cols-4 lg:p-12">
              {data?.map(({ type, ...data }, i) =>
                type === 'Nominee'
                  ? (
                  <VoteCard
                    key={`${Id}-${i}`}
                    {...data}
                      category_id={data.category_id}
                      sub_category={data.sub_category_id}
                  />
                    )
                  : (
                  <CategoriesCard {...data} key={`${Id}-${i}`} />
                    )
              )}
            </div>,
            <p className="mt-6 text-center text-xl font-bold">
              No Result for your query
            </p>
              ),
          <Loading className="mt-8" />
            )
          )
        : (
        <p>{''}</p>
          )}
      <Link href="/attest" className='hidden'>
        <span className="group mt-8 flex cursor-pointer gap-4 font-semibold text-white group-hover:ml-4">
          <span className="my-auto transition-all duration-500 group-hover:ml-6">
            {' '}
            View all categories
          </span>
          <Arrow className="my-auto h-6 w-6 transition-all duration-500 group-hover:ml-4" />
        </span>
      </Link>
      {show && (
        <>
          <span className="absolute top-10 left-0 z-0 lg:block hidden hover:scale-110 hover:rotate-12 transition-transform duration-300 ease-in">
            <FeaturesTopLeft className="w-56 h-auto" />
          </span>
          <span className="absolute bottom-10 left-16 z-0 lg:block hidden hover:scale-110 hover:rotate-12 transition-transform duration-300 ease-in">
            <FeaturesBottomLeft />
          </span>
          <span className="absolute bottom-10 right-0 z-0 lg:block hidden hover:scale-110 hover:-rotate-12 transition-transform duration-300 ease-in">
            <FeaturesRightGroup className="w-40 h-auto" />
          </span>
          <span className="absolute top-0 right-0 z-0 lg:block hidden hover:scale-110 hover:-rotate-12 transition-transform duration-300 ease-in">
            <FeaturesTopRight className="w-40 h-auto" />
          </span>
          <span className="absolute bottom-10 left-16 z-0 lg:hidden">
            <FeaturesBottomLeftMobile />
          </span>
          <span className="absolute bottom-0 right-0 z-0 lg:hidden">
            <FeaturesRightGroupMobile />
          </span>
          <span className="absolute top-10 left-0 z-0 lg:hidden">
            <FeaturesTopLeftMobile />
          </span>
          <span className="absolute top-[10%] right-12 z-0 lg:hidden">
            <FeaturesTopRightMobile />
          </span>
        </>
      )}
    </div>
  );
};

export default Hero;
